import React from "react"
import SubHeaderStyle from "./indexStyle"

interface Props {
  title: string
  downloadUrl?: string
  buttonText?: string
}

const SubHeader: React.FC<Props> = ({ title, downloadUrl, buttonText }) => {
  return (
    <SubHeaderStyle>
      <div className="container">
        <h1>{title}</h1>
        {downloadUrl && (
          <a href={`${downloadUrl}?dl=`} download target="_blank">
            {buttonText ?? "Download not starting? Click here"}
          </a>
        )}
      </div>
    </SubHeaderStyle>
  )
}

export default SubHeader
