import styled from "styled-components"

export default styled.section`
  .container {
    position: relative;
    display: grid;
    grid-template-columns: 40% 50%;
    grid-gap: 10%;
    align-items: center;
    padding: 6rem;
    border-radius: 0.6rem;
    background-color: var(--red);

    @media only screen and (max-width: 1200px) {
      grid-template-columns: 100%;
      grid-gap: 1rem;
      padding: 4rem;
    }

    &::after {
      content: "";
      position: absolute;
      left: 45%;
      display: block;
      width: 0.1rem;
      height: 3rem;
      opacity: 0.2;
      background-color: var(--white);

      @media only screen and (max-width: 1200px) {
        display: none;
      }
    }

    p {
      color: var(--white);
      font-size: 1.6rem;
      width: fit-content;
      margin-left: auto;

      @media only screen and (max-width: 1200px) {
        margin-bottom: 1.2rem;
        margin-left: unset;
      }
    }

    ._form_6 form {
      ._form-content {
        /* display: flex;
        flex-wrap: wrap;

        & > div {
          margin-right: 10px;
          max-width: 150px!important;
        } */
        position: relative;
        top: 0.5rem;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1rem;

        @media only screen and (max-width: 768px) {
          grid-template-columns: repeat(2, 1fr) !important;
        }

        @media only screen and (max-width: 500px) {
          grid-template-columns: repeat(1, 1fr) !important;
        }

        & > div {
          margin-bottom: 0 !important;

          input {
            color: white !important;
          }
        }

        @media only screen and (max-width: 668px) {
          #_form_6_submit {
            font-size: 1.4rem !important;
          }
        }
      }

      ._form-thank-you {
        text-align: left!important;
        color: white!important;
      }
    }

    form {
      display: flex;
      margin-right: auto;

      @media only screen and (max-width: 1000px) {
        flex-direction: column;
        margin-right: unset;
      }

      input[type="email"] {
        background-color: rgba(255, 255, 255, 0.2);
        border-width: 0;
        padding: 1rem 2rem;
        border-radius: 0.4rem;
        color: var(--white);

        @media only screen and (max-width: 900px) {
          text-align: center;
        }

        &::placeholder {
          color: var(--white);
        }

        &:focus {
          outline: none;
        }
      }

      input[type="submit"] {
        background-color: transparent;
        border-width: 0;
        text-decoration: underline;
        color: var(--white);
        padding: 1rem 2rem;
        font-size: 1.2rem;
        cursor: pointer;

        &:hover {
          opacity: 0.6;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
`