import styled from "styled-components"

export default styled.section`
  background-color: transparent;
  margin: 8rem 0;

  .container {
    & > h3 {
      color: var(--red) !important;
      font-size: 2.4rem;
      display: inline-block;
      margin-bottom: 0px;
      margin-right: 30px;
      min-width: 80px;
    }

    & > a {
      text-decoration: underline;
      display: inline-block;
    }

    .blogs {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 3rem;
      margin-top: 2rem;

      @media only screen and (max-width: 800px) {
        grid-template-columns: 1fr;
      }
    }
  }
`
