import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SubHeader from "../shared/subHeader"

const ThankYouPageHeader = () => {
  const { thankYouHeader } = useStaticQuery(graphql`
    query {
      thankYouHeader: sanityCalculatorThankYouPage {
        title
        downloadButtonText
        file {
          asset {
            url
            originalFilename
          }
        }
      }
    }
  `)

  return (
    <SubHeader
      title={thankYouHeader.title}
      downloadUrl={thankYouHeader.file.asset.url}
    />
  )
}

export default ThankYouPageHeader
