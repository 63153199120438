import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import BlogBlock from "../../blogs/blogBlock";
import BlogsSectionStyle from "./indexStyle";
import MyLink from "../sub/myLink";
import { Query } from "@graphql-types";

interface Props {
  title?: string;
  category?:
    | "All"
    | "Home Loans"
    | "Business Loans"
    | "Development Finance"
    | "Commercial Property Finance"
    | "Property Investment Lending"
    | "Other";
}

const BlogsSection: React.FC<Props> = ({ title = "Related Blogs", category }) => {
  const {
    allSanityBlogs: { nodes },
  }: Query = useStaticQuery(graphql`
    query {
      allSanityBlogs {
        nodes {
          ...sanityBlogsPreview
        }
      }
    }
  `);

  const blogsForRender = nodes
    .filter(node => {
      if (node == null || node.categories == null) return null;

      if (category == null || category === "All") return node;
      return node.categories.some(categoryNode => categoryNode?.CategoryName === category);
    })
    .slice(0, 3)
    .map(blog => blog);

  if (blogsForRender.length === 0) return null;
  return (
    <BlogsSectionStyle>
      <div className="container">
        <h3 className="section-title">{title}</h3>
        <MyLink className="theme-btn inline red" url="/blogs/">
          More Blogs
        </MyLink>

        <div className="blogs">
          {blogsForRender.map(blog => (
            <BlogBlock blog={blog} key={blog.slug?.current} />
          ))}
        </div>
      </div>
    </BlogsSectionStyle>
  );
};

export default BlogsSection;
