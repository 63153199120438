import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import NewsletterForm from "./activeHostedForm"

import NewsletterSection from "./newsletterBlockStyle"

const NewsletterBlock = () => {
  const {
    sanitySharedSection: {
      newsletterBlock: { newsletterDescription },
    },
  } = useStaticQuery(graphql`
    query {
      sanitySharedSection {
        newsletterBlock {
          newsletterDescription
        }
      }
    } 
  `)

  return (
    <NewsletterSection>
      <div className="container">
        <p>{newsletterDescription}</p>
        <NewsletterForm />
      </div>
    </NewsletterSection>
  )
}

export default NewsletterBlock
