import { TABLET_BREAKPOINT } from "@util/constants"
import styled from "styled-components"
import bkLogo from "./bkLogo.svg"

export default styled.section`
  display: flex;
  align-items: center;
  background: var(--red);
  min-height: 30rem;

  p {
    color: white;
  }

  .container {
    max-width: 1380px;
  }

  h1 {
    color: white;
    margin-bottom: 30px;
    font-size: 24px;
  }

  .category {
    border: 1px solid white;
    margin-bottom: 30px;
  }

  a {
    color: white;
    text-decoration: underline;
    font-family: "medium";
    :hover {
      color: white;
      opacity: 0.7;
    }

    svg {
      position: relative;
      top: 2px;
      left: 0px;
      transition: left 0.2s ease-out;
    }

    &:hover {
      svg {
        left: -6px;
      }
    }
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    p {
      margin-bottom: 20px;
    }
    h1 {
      font-size: 20px;
    }
  }
`
