import React, { useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"

const Download = () => {
  const downloadBtn = useRef(null)

  const {
    sanityCalculatorThankYouPage: {
      file: { asset },
    },
  } = useStaticQuery(graphql`
    query {
      sanityCalculatorThankYouPage {
        file {
          asset {
            url
            originalFilename
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (asset.url != "") downloadBtn.current.click()
  }, [])

  if (asset.url === "") return

    return (
      <a
        href={`${asset.url}?dl=${asset.originalFilename}`}
        download
        ref={downloadBtn}
        target="_blank"
        style={{ display: "none" }}
      ></a>
    )
}

export default Download
