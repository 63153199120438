import React from "react"
import ThankYouPageHeader from "../components/calculatorThankYou/ThankYouPageHeader"
import Ctas from "../components/calculatorThankYou/ctas"
import NewsletterBlock from "../components/shared/newsletter/newsletterBlock"
import ThankYouStyled from "../components/calculatorThankYou/ThankYou.style"
import BlogsSection from "../components/shared/blogsSection/index"
import Download from "../components/calculatorThankYou/download"
import { Helmet } from "react-helmet"

const CalculatorThankYouPage = () => {
  return (
    <ThankYouStyled>
      <div className="subpage-container">
        <Helmet>
          <title>
            Development Feasibility Calculator | Thank You | Lateral Partners
          </title>
        </Helmet>
        <ThankYouPageHeader />
        <Ctas />
        <NewsletterBlock />
        <BlogsSection />
        <Download />
      </div>
    </ThankYouStyled>
  )
}

export default CalculatorThankYouPage
